<template>
    <div class="card">
    <div class="card-content">
        <div class="media">
        <div class="media-left">
            <figure class="image is-48x48">
            <a href="/sundell_big.jpg"><img src="../assets/sundell_small.jpg" alt="Profile picture"></a>
            </figure>
        </div>
        <div class="media-content">
            <p class="title is-4">Ville Sundell / Solarius / 威力</p>
            <p class="subtitle is-6">
                Smart contract developer, auditor & entrepreneur
            </p>
        </div>
        </div>

        <div class="content">
            Smart contract developer since Bitcoin's Script. Currently developing and auditing on Ethereum, EOSIO and Move based blockchains. Programmer since the turn of the millennium.<br/>
            &nbsp;<br/>
            <a href="https://www.linkedin.com/in/villesundell/" class="button is-small"><font-awesome-icon icon="fa-brands fa-linkedin" />&nbsp; LinkedIn</a>&nbsp;
            <a href="https://github.com/villesundell" class="button is-small"><font-awesome-icon icon="fa-brands fa-github" />&nbsp; GitHub</a>&nbsp;
            <a href="mailto:sales@solarius.fi" class="button is-small"><font-awesome-icon icon="fa-solid fa-envelope" />&nbsp; Email</a>&nbsp;
            <a href="https://villesundell.fi" class="button is-small"><font-awesome-icon icon="fa-solid fa-gavel" />&nbsp; Poliitikkosivu</a>
        </div>
    </div>
    </div>
</template>

<script>
export default {
  name: 'ContactInfo'
}
</script>