<template>
  <section class="section">
    <div class="container">
      <div class="block">
        <ContactInfo/>
      </div>
    </div>
  </section>
</template>

<script>
import ContactInfo from './components/ContactInfo.vue'

export default {
  name: 'App',
  components: {
    ContactInfo
  }
}
</script>
